body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: black;
}

.homepage {
  color: white;
  padding: 20px;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}
