/* Add this CSS to your existing app.css file */

.homepage {
  color: #555; /* Changed text color for better readability */
  padding: 20px;
}

.content-section {
  margin-top: 40px;
}

.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-text {
  width: 70%;
  text-align: center;
}

.section-text h2 {
  font-size: 28px; /* Increased heading font size */
  margin-bottom: 20px;
  color: #686666; /* Changed heading color for better contrast */
}

.section-text p {
  font-size: 22px; /* Increased paragraph font size */
  line-height: 1.6;
  color: #777; /* Changed paragraph color for better contrast */
}

.section-image img {
  width: 200px;
  border-radius: 50%;
  margin-top: 20px;
}
